var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("van-nav-bar", { attrs: { title: "询单列表" } }),
      this.chunDatas.inquiryId
        ? _c("div", [
            _c(
              "div",
              { staticClass: "main", style: { height: _vm.mainHeight + "px" } },
              _vm._l(_vm.datasArr, function (item, index) {
                return _c("div", { key: index, staticClass: "card" }, [
                  _c("div", { staticClass: "cardTilte" }, [
                    _c(
                      "p",
                      { staticClass: "cardTilteP1" },
                      [
                        _c("van-icon", {
                          attrs: {
                            color: "#E64A19",
                            name: "like-o",
                            size: "15",
                          },
                        }),
                        _vm._v(
                          " 客户于 " +
                            _vm._s(_vm._f("dateFormatHms")(item.createTime)) +
                            " 发送 "
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.resourceChange[item.resourceType].type) +
                              " "
                          ),
                        ]),
                        _vm._v("询价需求"),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "cardTilteP2" }, [
                      _vm._v("询单编号:" + _vm._s(item.inquiryId)),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "cardBottom" },
                    [_c("inquiryChild", { attrs: { datas: item } })],
                    1
                  ),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "footer" }, [
              _c("div", { staticClass: "footerBtnBox" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "text-align": "center",
                    },
                  },
                  [
                    _c(
                      "wx-open-launch-weapp",
                      {
                        attrs: {
                          id: "launch-btn",
                          username: "gh_6aa8fe0c937c",
                          path: "pages/Login/Login",
                        },
                      },
                      [
                        _c(
                          "script",
                          { attrs: { type: "text/wxtag-template" } },
                          [
                            _vm._v(
                              ' <style>.btn { width: 200px; height: 30px; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 12px; background-color: #5FCF73; text-align: center; border-radius: 5px; border: none;border-color:#5FCF73; } </style> <button type="primary" class="btn">跳转小程序查看</button> '
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
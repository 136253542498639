export const resourceChange = {
    0: {
      code: "0",
      type: "酒店",
      types: "酒店需求",
      resouceType: "hotel",
      oneText: "住",
      stockType: -1, // -1 指的是专属和FIT都存在这个类型
    },
  
    1: {
      code: 1,
      type: "会议",
      types: "会议需求",
      resouceType: "site",
      oneText: "会",
      stockType: 2, // 只有专属有这个类型
    },
  
    2: {
      code: 2,
      type: "景点",
      types: "景点需求",
      resouceType: "spot",
      oneText: "景",
      stockType: -1, // -1 指的是专属和FIT都存在这个类型
    },
  
    3: {
      code: 3,
      type: "用车",
      types: "用车需求",
      resouceType: "traffic",
      oneText: "车",
      stockType: 2, // 只有专属有这个类型
    },
  
    4: {
      code: 4,
      type: "用餐",
      types: "用餐需求",
      resouceType: "restaurant",
      oneText: "餐",
      stockType: 2, // 只有专属有这个类型
    },
  
    5: {
      code: 5,
      type: "导游",
      types: "导游需求",
      resouceType: "guide",
      oneText: "导",
      stockType: -1, // -1 指的是专属和FIT都存在这个类型
    },
  
    6: {
      code: 6,
      type: "签证",
      types: "签证需求",
      resouceType: "visa",
      oneText: "签",
      stockType: -1, // -1 指的是专属和FIT都存在这个类型
    },
  
    7: {
      code: 7,
      type: "商务",
      types: "商务需求",
      resouceType: "business",
      oneText: "商",
      stockType: 2, // 只有专属有这个类型
    },
  
    8: {
      code: 8,
      type: "特色",
      types: "特色需求",
      resouceType: "charact",
      oneText: "特",
      stockType: -1, // -1 指的是专属和FIT都存在这个类型
    },
  
    9: {
      code: 9,
      type: "服务",
      types: "服务需求",
      resouceType: "service",
      oneText: "服",
      stockType: 2, // 只有专属有这个类型
    },
    15: {
      code: 15,
      type: "交通",
      types: "交通需求",
      resouceType: "plane",
      oneText: "交",
      stockType: 2, // 只有专属有这个类型
    },
  };
<template>
    <div class='container'>
        <div class="sty-div">
            <div class="sty-div-div2">人数：</div>
            <div class="sty-div-div1">
                {{ datas.peopleNumber }}
            </div>
        </div>
        <div class="sty-div">
            <div class="sty-div-div2">行程：</div>
            <div v-if="datas.resourceType === 10">
                <div v-for="(item, index) in datas.jsonObject.bigTrafficProductInquiries" :key="index"
                    style="display: flex;align-items: center;flex-wrap: wrap;">
                    <div class="sty-div-div1">{{ item.useDate }}</div>
                    <div class="sty-div-div1">开始城市:{{ item.cityName }}</div>
                    <div class="sty-div-div1">结束城市:{{ item.endCityName }}</div>
                    <div class="sty-div-div1">{{ item.trafficType }}</div>
                    <div class="sty-div-div1">{{ item.expectFlight }}</div>
                </div>
            </div>
            <div class="sty-div-div1" style="flex: 1;display: flex;align-items: center;flex-wrap: wrap;"
                v-if="datas.resourceType != 1">
                <div v-for="(item, index) in datas.planInfo" :key="index">
                    <div v-if="item" class="sty-div-div1">
                        {{ item }}
                    </div>
                </div>
            </div>
            <div style="flex: 1;display: flex;align-items: center;flex-wrap: wrap;" v-else>
                <div class="sty-div-div1">{{ datas.jsonObject.useDate }}</div>
                <div class="sty-div-div1">{{ datas.jsonObject.useDay }}</div>
                <div class="sty-div-div1">{{ datas.jsonObject.cityName }}</div>
                <div class="sty-div-div1">{{ datas.jsonObject.activeType }}</div>
                <div class="sty-div-div1">预算： {{ datas.jsonObject.budgetPrice }}{{ datas.jsonObject.currency }}
                </div>
            </div>
        </div>
        <div v-if="datas.resourceType !== 10" class="sty-div" style="margin-bottom: 0;">
            <div class="sty-div-div2">需求：</div>
            <div class="sty-div-div1" style="flex: 1;display: flex;flex-direction: column;">
                <div style="margin-right: 12px;" v-if="datas.resourceType == 1">
                    {{ datas.needDetail.join(' ') }}
                </div>
                <div v-if="datas.resourceType == 2">
                    <div v-for="(n, m) in datas.jsonObject.spotProductInquiryList" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">{{ n.useDate }}</span>
                        <span class="sty-div-div1">{{ n.cityName }}</span>
                        <span class="sty-div-div1">{{ n.spotName }}</span>
                        <span class="sty-div-div1">{{ n.spotType }}</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 3">
                    <div v-for="(n, m) in datas.jsonObject.trafficProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">{{ n.busType }}</span>
                        <span class="sty-div-div1">{{ n.trafficType }}</span>
                        <span class="sty-div-div1">{{ n.busNumber }}辆</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 4">
                    <div v-for="(n, m) in datas.jsonObject.mealProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">城市:{{ n.cityName }}</span>
                        <span class="sty-div-div1">类型:{{ n.mealType }}</span>
                        <span class="sty-div-div1">菜名:{{ n.meal }}</span>
                        <span class="sty-div-div1">数量:{{ n.number }}顿</span>
                        <span class="sty-div-div1">预算:{{ n.budgetPrice }}{{ n.currency }}</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 5">
                    <div v-for="(n, m) in datas.jsonObject.trafficProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">{{ n.trafficType }}</span>
                        <span v-if="n.busType" class="sty-div-div1">{{ n.busType }}</span>
                        <span class="sty-div-div1">{{ n.busNumber }}人</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 6">
                    <div v-for="(n, m) in datas.jsonObject.visaProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">国家:{{ n.countryName }}</span>
                        <span class="sty-div-div1">签证类型:{{ n.visaType }}</span>
                        <span class="sty-div-div1">目的:{{ n.visaContent }}</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 7">
                    <div v-for="(n, m) in datas.jsonObject.businessProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">城市:{{ n.cityName }}</span>
                        <span class="sty-div-div1">行业:{{ n.businessIndustry }}</span>
                        <span class="sty-div-div1">时长:{{ n.time }}</span>
                        <span class="sty-div-div1">目的:{{ n.objective }}</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 8">
                    <div v-for="(n, m) in datas.jsonObject.chractProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">城市:{{ n.cityName }}</span>
                        <span class="sty-div-div1">特色体验:{{ n.content ? n.content : '' }}</span>
                    </div>
                </div>
                <div v-if="datas.resourceType == 9">
                    <div v-for="(n, m) in datas.jsonObject.chractProductInquiries" :key="m">
                        <span v-if="n.addType" class="sty-div-div1" style="color: #72AED3;">{{ n.addType }}</span>
                        <span class="sty-div-div1">城市:{{ n.cityName }}</span>
                        <span class="sty-div-div1">服务:{{ n.content ? n.content : '' }}</span>
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;">
                    <div class="" v-for="(item, index) in datas.hotelRoomList" :key="index">
                        <div>
                            {{ item.hotelRoomName }} {{ item.roomNumber }} 间 预算:{{ item.roomBudget }}{{ item.currency }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        //渲染的数据
        datas: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {

        }
    },
    computed: {},
    watch: {},
    methods: {
       
    },
    created() {

    },
    mounted() {

    },
}
</script>
<style lang='scss' scoped>
.container {
    .sty-div {
        display: flex;
        margin-bottom: 0.12rem;

        .sty-div-div1 {
            margin-right: 0.24rem;
            font-size: 0.24rem;
            font-family: PingFangSC-regular;
            color: #101010;
        }

        .sty-div-div2 {
            font-size: 0.24rem;
            font-family: PingFangSC-regular;
            color: rgba(230, 74, 25, 0.8);
            min-width: 0.9rem;
        }
    }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "sty-div" }, [
      _c("div", { staticClass: "sty-div-div2" }, [_vm._v("人数：")]),
      _c("div", { staticClass: "sty-div-div1" }, [
        _vm._v(" " + _vm._s(_vm.datas.peopleNumber) + " "),
      ]),
    ]),
    _c("div", { staticClass: "sty-div" }, [
      _c("div", { staticClass: "sty-div-div2" }, [_vm._v("行程：")]),
      _vm.datas.resourceType === 10
        ? _c(
            "div",
            _vm._l(
              _vm.datas.jsonObject.bigTrafficProductInquiries,
              function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "flex-wrap": "wrap",
                    },
                  },
                  [
                    _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v(_vm._s(item.useDate)),
                    ]),
                    _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v("开始城市:" + _vm._s(item.cityName)),
                    ]),
                    _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v("结束城市:" + _vm._s(item.endCityName)),
                    ]),
                    _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v(_vm._s(item.trafficType)),
                    ]),
                    _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v(_vm._s(item.expectFlight)),
                    ]),
                  ]
                )
              }
            ),
            0
          )
        : _vm._e(),
      _vm.datas.resourceType != 1
        ? _c(
            "div",
            {
              staticClass: "sty-div-div1",
              staticStyle: {
                flex: "1",
                display: "flex",
                "align-items": "center",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.datas.planInfo, function (item, index) {
              return _c("div", { key: index }, [
                item
                  ? _c("div", { staticClass: "sty-div-div1" }, [
                      _vm._v(" " + _vm._s(item) + " "),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          )
        : _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                display: "flex",
                "align-items": "center",
                "flex-wrap": "wrap",
              },
            },
            [
              _c("div", { staticClass: "sty-div-div1" }, [
                _vm._v(_vm._s(_vm.datas.jsonObject.useDate)),
              ]),
              _c("div", { staticClass: "sty-div-div1" }, [
                _vm._v(_vm._s(_vm.datas.jsonObject.useDay)),
              ]),
              _c("div", { staticClass: "sty-div-div1" }, [
                _vm._v(_vm._s(_vm.datas.jsonObject.cityName)),
              ]),
              _c("div", { staticClass: "sty-div-div1" }, [
                _vm._v(_vm._s(_vm.datas.jsonObject.activeType)),
              ]),
              _c("div", { staticClass: "sty-div-div1" }, [
                _vm._v(
                  "预算： " +
                    _vm._s(_vm.datas.jsonObject.budgetPrice) +
                    _vm._s(_vm.datas.jsonObject.currency) +
                    " "
                ),
              ]),
            ]
          ),
    ]),
    _vm.datas.resourceType !== 10
      ? _c(
          "div",
          { staticClass: "sty-div", staticStyle: { "margin-bottom": "0" } },
          [
            _c("div", { staticClass: "sty-div-div2" }, [_vm._v("需求：")]),
            _c(
              "div",
              {
                staticClass: "sty-div-div1",
                staticStyle: {
                  flex: "1",
                  display: "flex",
                  "flex-direction": "column",
                },
              },
              [
                _vm.datas.resourceType == 1
                  ? _c("div", { staticStyle: { "margin-right": "12px" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.datas.needDetail.join(" ")) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.datas.resourceType == 2
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.spotProductInquiryList,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.useDate)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.cityName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.spotName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.spotType)),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 3
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.trafficProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.busType)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.trafficType)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.busNumber) + "辆"),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 4
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.mealProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("城市:" + _vm._s(n.cityName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("类型:" + _vm._s(n.mealType)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("菜名:" + _vm._s(n.meal)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("数量:" + _vm._s(n.number) + "顿"),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(
                                "预算:" +
                                  _vm._s(n.budgetPrice) +
                                  _vm._s(n.currency)
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 5
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.trafficProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.trafficType)),
                            ]),
                            n.busType
                              ? _c("span", { staticClass: "sty-div-div1" }, [
                                  _vm._v(_vm._s(n.busType)),
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(_vm._s(n.busNumber) + "人"),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 6
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.visaProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("国家:" + _vm._s(n.countryName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("签证类型:" + _vm._s(n.visaType)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("目的:" + _vm._s(n.visaContent)),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 7
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.businessProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("城市:" + _vm._s(n.cityName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("行业:" + _vm._s(n.businessIndustry)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("时长:" + _vm._s(n.time)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("目的:" + _vm._s(n.objective)),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 8
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.chractProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("城市:" + _vm._s(n.cityName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(
                                "特色体验:" + _vm._s(n.content ? n.content : "")
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _vm.datas.resourceType == 9
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.datas.jsonObject.chractProductInquiries,
                        function (n, m) {
                          return _c("div", { key: m }, [
                            n.addType
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "sty-div-div1",
                                    staticStyle: { color: "#72AED3" },
                                  },
                                  [_vm._v(_vm._s(n.addType))]
                                )
                              : _vm._e(),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v("城市:" + _vm._s(n.cityName)),
                            ]),
                            _c("span", { staticClass: "sty-div-div1" }, [
                              _vm._v(
                                "服务:" + _vm._s(n.content ? n.content : "")
                              ),
                            ]),
                          ])
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                    },
                  },
                  _vm._l(_vm.datas.hotelRoomList, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(item.hotelRoomName) +
                            " " +
                            _vm._s(item.roomNumber) +
                            " 间 预算:" +
                            _vm._s(item.roomBudget) +
                            _vm._s(item.currency) +
                            " "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
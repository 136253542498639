import { Service,ServiceToC } from "../Axios/Service";
import qs from "qs";
import store from "../../store";
const PROFILE = {
  inquiry: "VUE_APP_INQUIRY_PREFIX",
};

function getType(apiType) {
  return process.env[PROFILE[apiType]];
}

class InquiryApi {
    constructor(ApiType) {
        this.ApiType = ApiType;
    }
    //询单列表
    InquiryList(obj) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
          url: `/${apiType}/supplierApi/projectInquiry/h5List`,
          // url: "http://localhost:9500/wintac-inquiry-web-test-sx/supplierApi/projectInquiry/list",
          method: "post",
          data: obj,
        });
    }
 
}
export default InquiryApi;
<!-- 询单 -->
<template>
    <div class='container'>
        <van-nav-bar title="询单列表"> </van-nav-bar>
        <div v-if="this.chunDatas.inquiryId">
            <div class="main" :style="{ height: mainHeight + 'px' }">
                <div class="card" v-for="(item, index) in datasArr" :key="index">
                    <div class="cardTilte">
                        <p class="cardTilteP1"> <van-icon color="#E64A19" name="like-o" size="15" /> 客户于 {{
                            item.createTime | dateFormatHms }} 发送 <span>{{ resourceChange[item.resourceType].type }}
                            </span>询价需求</p>
                        <p class="cardTilteP2">询单编号:{{ item.inquiryId }}</p>
                    </div>
                    <div class="cardBottom">
                        <inquiryChild :datas="item"></inquiryChild>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div class="footerBtnBox">
                    <!-- <van-button class="footerBtns" size="small" type="primary">
                        跳转微信小程序</van-button> -->
                    <div style="width: 100%; height: 100%;text-align: center;">
                        <wx-open-launch-weapp id="launch-btn" username="gh_6aa8fe0c937c" path="pages/Login/Login">
                            <script type="text/wxtag-template">
                                <style>.btn {   width: 200px;
                                                height: 30px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                color: #fff;
                                                font-size: 12px;
                                                background-color: #5FCF73;
                                                text-align: center;
                                                border-radius: 5px;
                                                border: none;border-color:#5FCF73;
                                        }
                                </style>
    	                                        <button type="primary" class="btn">跳转小程序查看</button>
    	                                    </script>
                        </wx-open-launch-weapp>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import inquiryChild from './inquiryChild.vue'
import { resourceChange } from './inquiry'
import InquiryApi from '@/components/Api/inquiry'
import Api from '@/components/Api/index'
import { Toast } from 'vant';
import { getUrlParams } from '@/utils/unit'
import { mapMutations, mapState } from "vuex";
export default {
    components: {
        inquiryChild
    },
    data() {
        return {
            mainHeight: 0,
            resourceChange,
            datasArr: [],
            chunDatas: {},
            mpPath: '',
        }
    },
    computed: {
        ...mapState('collection', ['inquiryId'])
    },
    watch: {},
    created() {
    },
    methods: {
        ...mapMutations('collection', ['SET_OPENID', 'SET_INQUIRYID']),
        getJsapi() {
            let url = window.location.href.split('#')[0]
            new Api("user").obtainSignatureData(url).then(res => {
                console.log(res, '看看上课的');
                if (res.status == 200) {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: res.data.appId, // 必填，公众号的唯一标识
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                        signature: res.data.signature,// 必填，签名
                        jsApiList: [
                            'chooseImage', 'previewImage'
                        ], // 必写，否则不显示
                        openTagList: ['wx-open-launch-weapp'] // 必写，否则不显示
                    });
                    wx.ready(function () {
                        console.log("ready success");
                    });
                    wx.error(function (res) {
                        console.log("error fail", res);
                    });
                } else {
                    Toast(res.statusText);
                }
            })
        },
        getDatas() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let obj = { "page": 1, "q": { "id": this.inquiryId, "userId": this.chunDatas.userId }, "size": 999 }
            // let obj = { "page": 1, "q": { "id": '340', "userId": 77 }, "size": 5 }
            if (obj.q.id) {
                new InquiryApi("inquiry").InquiryList(obj).then(res => {
                    if (res.status == 200) {
                        this.datasArr = res.data.records
                        console.log(this.datasArr, '时刻打开打开打开');
                        setTimeout(() => {
                            const footerHeight = this.$el.querySelector('.footer').clientHeight;
                            this.mainHeight = window.innerHeight - footerHeight - 92;
                        })
                        Toast.clear();
                        if (this.$route.query.i) {
                            this.getJsapi()
                        } else {
                            var url = window.location.href.split("?")[0];
                            url = url + "?i=" + this.chunDatas.userId + "-" + this.chunDatas.inquiryId
                            new Api("user").authorizationCallbackAddressH5Transmit("-2", url).then(res => {
                                window.open(res.data, '_self')
                            })
                        }
                    } else {
                        Toast(res.statusText);
                    }
                })
            } else {
                Toast('此Id无法查询数据！');
            }
        },
    },
    mounted() {
        this.chunDatas = {
            inquiryId: getUrlParams('inquiryId'),
            userId: getUrlParams('userId'),
            i: getUrlParams("i")
        }
        if (this.chunDatas.i) {
            let i = getUrlParams('i').split("-")
            let openId = getUrlParams('openid')
            this.chunDatas.inquiryId = i[1]
            this.chunDatas.userId = i[0]
        }
        this.SET_INQUIRYID(this.chunDatas.inquiryId)
        this.getDatas()
    },
}
</script>
<style lang='scss' scoped>
.container {
    .main {
        padding: 0.2rem;
        overflow-y: scroll;

        .card {
            border-radius: 0.05rem;
            background-color: rgba(255, 255, 255, 1);
            border: 1px solid rgba(242, 242, 242, 1);
            margin-bottom: 0.2rem;

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            .cardTilte {
                padding: 0.15rem;

                .cardTilteP1 {
                    color: rgba(149, 149, 149, 1);
                    font-size: 0.24rem;
                    font-family: SourceHanSansSC-regular;

                    span {
                        color: #E64A19;
                    }
                }

                .cardTilteP2 {
                    color: rgba(16, 16, 16, 1);
                    font-size: 0.24rem;
                    font-family: PingFangSC-medium;
                }
            }

            .cardBottom {
                padding: 0.13rem 0.1rem;
                border-radius: 0 0 0.05rem 0.05rem;
                background-color: rgba(250, 250, 250, 1);
            }
        }
    }

    .footer {
        width: 100%;

        .footerBtnBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .footerBtns {
                width: 100%;
            }
        }
    }
}
</style>
